import React from 'react';
import './App.css';

class App extends React.Component {
  onClick(){
    window.open('https://www.customink.com/fundraising/wellnest')
  }
  render(){
    return(
      <div className="App">
        <header className="App-header">
          <h1>Mental health affects <u>everyone.</u></h1>
          <h2>However, BIPOC do not have the same access to mental health resources as their white counterparts.</h2>
          <h3>Help us change that.</h3>
          <button onClick={this.onClick} className="supportButton">Support</button>
          <a href="https://wellnest.co/">-Wellnest Team</a>
        </header>
      </div>
    )
  }
}

export default App;
